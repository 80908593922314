import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

// import Homepage from "./Components/besthotels/Homepage";
import Homepage from "./Components/cricify/Homepage"
import TermsConditions from "./Components/cricify/Terms&consitions"
import PrivacyPolicy from "./Components/cricify/Privacyandpolicy"
import Gamesresponsibity from "./Components/cricify/Gameresponsibilty"
import ScrollToTop from "./Components/cricify/Scrollontop";
import Footer from "./Components/cricify/Footer";
// import Homepage from "./Components/Swish/Homepage";

// import Homepage from "../../phantom/src/Components/crickleads/Homepage";
// import Homepage from "./Components/Diamondexchnage/Homepage";
// import Landingpage from "../../phantom/src/Components/crickleads/Homepage"
// import Homepage from "../../phantom/src/Components/cricfans/Homepage";
// import Homepage from "./Components/WickBuzz/Homepage";
// import Homepage from "../../excel/client/src/components/khelocricket/homepage";
// import PrivacyPolicy from "../../excel/client/src/components/khelocricket/Privacypolicy"
// import TermsConditions from "../../excel/client/src/components/khelocricket/Termscondition"

// import Cookiespolicy from "../../excel/client/src/components/khelocricket/cookies"
// import Navbar from "../../excel/client/src/components/khelocricket/navbar"
// import Footer from "../../excel/client/src/components/khelocricket/Footer"
// import ScrollToTop from "../../excel/client/src/components/khelocricket/Scrollontop"
// import Homepage from "./Components/WickBuzz/Homepage";

// import Homepage from "./Components/khelocricket/homepage";
// import PrivacyPolicy from "./Components/crickleads/Privacypolicy";
// import TermsConditions from "./Components/crickleads/Terms&conditions";
// import Cookiespolicy from "./Components/crickleads/Cookies";
// import Navbar from "./Components/crickleads/Navbar";
// import Footer from "./Components/crickleads/Footer";
// import ScrollToTop from "./Components/crickleads/Scrollontop";

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/games" element={<Gamesresponsibity />} />
        </Routes>
        <Footer />
      </div>
    </Router>

  );
}

export default App;
