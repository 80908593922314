import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ handleClaimNowClick }) => (
  <footer className="bg-gray-800 text-white px-5 py-4">
    <div className="container space-y-4 space-x-3 mx-auto text-center">
      <div className="flex justify-center space-x-4 mt-2">
        <Link
          to="/"
          className="text-gray-400 hover:text-gray-200"
        >
          Home
        </Link>
        <Link
          to="/privacy"
          className="text-gray-400 hover:text-gray-200"
        >
          Privacy & policy
        </Link>
        <Link
          to="/terms"
          className="text-gray-400 hover:text-gray-200"
        >
          Terms & conditions
        </Link>
        <Link
          to="/games"
          className="text-gray-400 hover:text-gray-200"
        >
          Gameresponsibilty
        </Link>
      </div>
      <input
        type="email"
        placeholder="Enter your email"
        className="mt-2 px-4 py-2 rounded-lg border border-gray-300 w-1/2"
      />
      <button
        onClick={() => window.open("https://wa.link/ggnow", "_blank")}
        className="mt-2 bg-yellow-500 text-gray-800 px-4 py-2 rounded-lg hover:bg-yellow-600"
      >
        Subscribe
      </button>

      <p className="mt-4">Subscribe for more updates!</p>
      <div className="flex justify-center space-x-4 mt-2">
        <h1
          onClick={() => window.open("https://wa.link/ggnow", "_blank")}
          className="text-gray-400 hover:text-gray-200"
        >
          Facebook
        </h1>
        <h1
           onClick={() => window.open("https://wa.link/ggnow", "_blank")}
          className="text-gray-400 hover:text-gray-200"
        >
          Twitter
        </h1>
        <h1
           onClick={() => window.open("https://wa.link/ggnow", "_blank")}
          className="text-gray-400 hover:text-gray-200"
        >
          Instagram
        </h1>
      </div>
      <p className="mt-4 text-sm md:text-base leading-relaxed">
            Disclaimer: Participation in this platform is strictly for users
            aged 18 years or older.  
          </p>
          <strong className="text-red-600">We only promote fantasy sports. No real money involvement.</strong>
          <h1 className="text-yellow-500 font-bold mt-5"> Please note that we
            do not target users from Andhra Pradesh, Telangana, Assam, Sikkim,
            Odisha, and Tamil Nadu, as fantasy sports are prohibited in these
            states. We adhere strictly to Google Ads policies and operate in
            compliance with applicable laws and regulations.</h1>
      <p>&copy; 2024 cricify. Follow us on:</p>
    </div>
  </footer>
);

export default Footer;
