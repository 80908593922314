import React from "react";

const Gamesresponsibity = () => {
  return (
    <div className="min-h-screen p-5 bg-gray-100">
      <div className="px-4 md:px-10  lg:px-20 py-8 bg-white shadow-md rounded-md max-w-3xl mx-auto mt-10">
        <h1 className="text-2xl md:text-3xl font-bold text-center text-gray-800 mb-6">
          Games Responsibility
        </h1>
        <p>
     we prioritize fair, enjoyable, and responsible
          gaming experiences for all our users. Below, we outline our
          commitments and responsibilities regarding the games offered on our
          platform. Commitment to Fair Play We are dedicated to ensuring that
          all games provided on our website adhere to the highest standards of
          fairness and integrity. Our games are designed and tested to provide
          unbiased outcomes, and we continually review our systems to maintain
          this standard. We use advanced technology to prevent cheating, fraud,
          and any form of manipulation. Compliance with Regulations We strictly
          comply with all relevant local and international gaming laws and
          regulations. Our platform undergoes regular audits and evaluations to
          ensure adherence to legal and ethical standards. We also cooperate
          with regulatory authorities to maintain transparency and
          trustworthiness in our gaming services. User Safety and Well-Being We
          recognize the importance of responsible gaming and actively promote
          safe gaming practices. Our platform includes features that allow users
          to set limits on gameplay, spending, and time to avoid overindulgence.
          Additionally, we provide resources and support for users who may
          experience challenges related to gaming. Privacy and Security
          Protecting your personal information is a top priority for us. Our
          games and platform are built with robust security measures to
          safeguard user data. We do not share your information with third
          parties without your consent, and our systems are regularly updated to
          protect against any vulnerabilities. Feedback and Concerns Your
          feedback is invaluable to us. If you encounter any issues or have
          concerns about our games, we encourage you to contact us promptly. Our
          dedicated support team is available to assist you and address your
          concerns in a timely and efficient manner. Encouraging Ethical Gaming
          We believe in fostering a community of ethical gamers. To this end, we
          monitor player behavior on our platform and take appropriate action
          against those who violate our terms of service or engage in unfair
          practices. Our goal is to create a positive and inclusive environment
          for all players. By upholding these principles, we aim to provide a
          safe, fair, and enjoyable gaming experience for everyone. For further
          questions or assistance, feel free to reach out to our support team
        
        </p>
      </div>
    </div>
  );
};

export default Gamesresponsibity;
