import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import Footer from "./Footer";

const Homepage = () => {
  useEffect(() => {
    ReactGA.initialize("G-V2DDGTFJ91");
    ReactGA.send("pageview");
  }, []);

  const handleClaimNowClick = () => {
    ReactGA.event({
      category: "CTA",
      action: "Claim Now Button Clicked",
      label: "Claim Bonus",
    });
    window.open("https://wa.link/ggnow", "_blank");
  };

  return (
    <div
    
    onClick={handleClaimNowClick}
    className="w-full md:h-[90vh] bg-gray-200 h-full items-center">
      <div className="w-full h-full relative flex justify-center items-center">
        <img
          src="/cricket match template.jpg"
          alt="Hotel and Resort Promo"
          className="max-w-full max-h-full object-contain"
        />
        <button
          onClick={handleClaimNowClick}
          className="px-12 py-2 bg-yellow-500  p-5 rounded-md text-white font-bold animate-bounce absolute  bottom-20"
        >
          Whatsapp now
        </button>
      </div>
    </div>
  );
};

export default Homepage;
